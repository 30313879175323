
.background-image {
    background: url("../../images/img/contact.jpg"),  linear-gradient(to right, #60A5FA, #e1e1e3, #e1e1e3);
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
  }
  
  
  .bg-image-banner {
    


    margin: 0;
    padding: 0;
    background-image: url('../../images/mbbs-images/banner.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  


  .custom-div {
    background-size: 100% auto;
    background-repeat: no-repeat;
    
    margin: 0;
    padding: 0;
  
    /* Medium screen size (md) */
    @media screen and (min-width: 640px) {
      background-image: url('../../images/mbbs-images/banner.png');
    }
  
    /* Small screen size (sm) */
    @media screen and (max-width: 639px) {
      /* background-size: 100% auto; */
      background-position: center center;
      background-size: contain;
      background-image: url('../../images/Website-img/mbBanner.png');

    }
  
    /* Extra-large screen size (xl) */
    @media screen and (min-width: 1280px) {
      height: 530px;
    }
  }
  
  
  .bg-image-doctor {

    margin: 0;
    padding: 0;
    background-image: url('../../images/mbbs-images/best.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment:scroll ;
    
  }

  .responsive-bg-image {
    background-image: url('../../images/Website-img/About us.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: calc(351 / 740 * 100%);
    position: relative;
  }
  